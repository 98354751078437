<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="数据测试工具" />
        <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
                <span>清理评估和干预数据</span>
            </div>
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="身份证号">
                    <el-input v-model="form.SFZH" placeholder="请输入" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="handleClearPG">清理</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            loading: false,
            form: {
                SFZH: ''
            }
        }
    },
    methods: {
        async handleClearPG() {
            this.loading = true
            try {
                const resp = await this.$http.post(`/tools/clearPGBySFZH`, this.form)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
    }
}

</script>

<style scoped>
.box-card {
    margin-top: 16px;
}
</style>